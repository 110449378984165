import React from 'react'
import Img from 'gatsby-image'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import HTMLContent from '../HTMLContent'

function buildColumnGrid(columns) {
  if (!columns || !columns.length) return
  switch (columns.length) {
    case 1:
      return `col-12_tp-12_ds-12`
    case 2:
      return `col-12_tp-6_ds-6`
    case 3:
      return `col-12_tp-6_ds-4`
    case 4:
      return `col-12_tp-6_ds-3`
    case 5:
    default:
      return `col-12_tp-4_ds-4`
  }
}

const Column = ({
  align,
  txtAbove,
  img,
  icon,
  txt_MD,
  iconImg,
  grid,
  inlineImages,
}) => {
  const classNames = [
    grid,
    iconImg ? 'icon-img' : '',
    align ? `align${align}` : 'align-center',
  ]
    .filter((i) => i)
    .join(' ')

  const imgCol =
    !!icon && !!icon.src && !!icon.src.publicURL ? (
      <img key={uuidv4()} src={icon.src.publicURL} alt={icon.alt} />
    ) : !!img && !!img.src && !!img.src.childImageSharp ? (
      <Img key={uuidv4()} fluid={img.src.childImageSharp.fluid} alt={img.alt} />
    ) : null

  const txtCol = !!txt_MD ? (
    <HTMLContent key={uuidv4()} content={txt_MD} inlineImages={inlineImages} />
  ) : null

  const main = !!txtAbove ? [txtCol, imgCol] : [imgCol, txtCol]

  return <div className={classNames}>{main}</div>
}

Column.propTypes = {
  align: PropTypes.string,
  txtAbove: PropTypes.bool,
  img: PropTypes.shape({
    src: PropTypes.object.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  icon: PropTypes.shape({
    src: PropTypes.object.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  txt_MD: PropTypes.string,
  iconImg: PropTypes.bool,
  grid: PropTypes.string.isRequired,
  inlineImages: PropTypes.array,
}

const ColumnsPanel = ({ columns, inlineImages }) => {
  const grid = buildColumnGrid(columns)

  return (
    <div className="grid-spaceAround columns-container">
      {columns.map((col) => (
        <Column
          key={uuidv4()}
          grid={grid}
          inlineImages={inlineImages}
          {...col}
        />
      ))}
    </div>
  )
}

ColumnsPanel.propTypes = {
  columns: PropTypes.array.isRequired,
  inlineImages: PropTypes.array,
}

export default ColumnsPanel
